<template>
  <TransitionRoot as="template" :show="open">
    <Dialog
      as="div"
      static
      class="fixed inset-0 overflow-hidden"
      @close="deleteOpen ? null : $emit('close')"
      :open="open && !deleteOpen"
    >
      <div class="absolute inset-0 overflow-hidden">
        <TransitionChild
          as="template"
          enter="ease-in-out duration-500"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in-out duration-500"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="
              absolute
              inset-0
              bg-gray-500 bg-opacity-75
              transition-opacity
            "
          />
        </TransitionChild>

        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-md">
              <div
                class="
                  h-full
                  flex flex-col
                  py-6
                  bg-white
                  shadow-xl
                  overflow-y-scroll
                "
              >
                <div class="px-4 sm:px-6">
                  <div class="flex items-start justify-between">
                    <DialogTitle class="text-lg font-medium text-gray-900">
                      {{ this.isEdit ? "Edit" : "Add New" }} Queue Call
                    </DialogTitle>
                    <div class="ml-3 h-7 flex items-center">
                      <button
                        class="
                          bg-white
                          rounded-md
                          text-gray-400
                          hover:text-gray-500
                          focus:outline-none
                        "
                        @click="$emit('close')"
                      >
                        <span class="sr-only">Close panel</span>
                        <XIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
                <div class="mt-2 relative flex-1">
                  <!-- Replace with your content -->
                  <div class="">
                    <form
                      class="h-full divide-y divide-gray-200 flex flex-col"
                      @submit="save"
                    >
                      <div class="flex-1 h-0 overflow-y-auto">
                        <div class="flex-1 flex flex-col justify-between">
                          <div class="px-4 divide-y divide-gray-200 sm:px-6">
                            <div class="space-y-6 pt-6 pb-5">
                              <div>
                                <label
                                  for="number"
                                  class="
                                    block
                                    text-sm
                                    font-medium
                                    text-gray-900
                                  "
                                >
                                  Number *
                                </label>
                                <div class="mt-1">
                                  <input
                                    v-model="number"
                                    id="number"
                                    placeholder="Number"
                                    type="text"
                                    class="
                                      block
                                      w-full
                                      shadow-sm
                                      sm:text-sm
                                      focus:ring-indigo-500
                                      focus:border-indigo-500
                                      border-gray-300
                                      rounded-md
                                    "
                                  />
                                  <p
                                    v-if="destination"
                                    class="
                                      whitespace-nowrap
                                      text-sm text-gray-500
                                    "
                                  >
                                    {{ destination }}
                                  </p>
                                </div>
                              </div>
                              <div>
                                <label
                                  for="caller_id_num"
                                  class="
                                    block
                                    text-sm
                                    font-medium
                                    text-gray-900
                                  "
                                >
                                  Caller ID *
                                </label>
                                <div class="mt-1 w-full inline-flex">
                                  <input
                                    v-model="caller_id_num"
                                    id="caller_id_num"
                                    placeholder="Caller ID"
                                    type="text"
                                    class="
                                      block
                                      w-full
                                      shadow-sm
                                      sm:text-sm
                                      focus:ring-indigo-500
                                      focus:border-indigo-500
                                      border-gray-300
                                      rounded-md
                                    "
                                  />
                                  <Menu as="span" class="relative block">
                                    <MenuButton
                                      class="
                                        border-2 border-l-0
                                        inline-flex
                                        items-center
                                        px-2
                                        py-2
                                        rounded-r-md
                                        text-sm
                                        font-medium
                                        hover:bg-gray-100
                                        focus:outline-none
                                      "
                                    >
                                      <span class="sr-only">Open options</span>
                                      <ChevronDownIcon
                                        class="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </MenuButton>
                                    <transition
                                      enter-active-class="transition ease-out duration-100"
                                      enter-from-class="transform opacity-0 scale-95"
                                      enter-to-class="transform opacity-100 scale-100"
                                      leave-active-class="transition ease-in duration-75"
                                      leave-from-class="transform opacity-100 scale-100"
                                      leave-to-class="transform opacity-0 scale-95"
                                    >
                                      <MenuItems
                                        class="
                                          z-50
                                          origin-top-right
                                          absolute
                                          right-0
                                          mt-2
                                          -mr-1
                                          rounded-md
                                          shadow-lg
                                          bg-white
                                          ring-1 ring-black ring-opacity-5
                                          focus:outline-none
                                        "
                                      >
                                        <div class="py-1">
                                          <MenuItem
                                            v-for="DID in DIDs"
                                            :key="DID.did_number"
                                            v-slot="{ active }"
                                            @click="
                                              caller_id_num = DID.did_number
                                            "
                                          >
                                            <a
                                              href="#"
                                              :class="[
                                                active
                                                  ? 'bg-gray-100 text-gray-900'
                                                  : 'text-gray-700',
                                                'block px-4 py-2 text-sm',
                                              ]"
                                            >
                                              {{ DID.did_number }} ({{
                                                DID.mailbox_name
                                              }})
                                            </a>
                                          </MenuItem>
                                        </div>
                                      </MenuItems>
                                    </transition>
                                  </Menu>
                                </div>
                              </div>
                              <div>
                                <label
                                  for="caller_id_name"
                                  class="
                                    block
                                    text-sm
                                    font-medium
                                    text-gray-900
                                  "
                                >
                                  CID Name
                                </label>
                                <div class="mt-1">
                                  <input
                                    v-model="caller_id_name"
                                    id="caller_id_name"
                                    placeholder="Caller Name"
                                    type="text"
                                    class="
                                      block
                                      w-full
                                      shadow-sm
                                      sm:text-sm
                                      focus:ring-indigo-500
                                      focus:border-indigo-500
                                      border-gray-300
                                      rounded-md
                                    "
                                  />
                                </div>
                              </div>
                              <div>
                                <label
                                  for="notes"
                                  class="
                                    block
                                    text-sm
                                    font-medium
                                    text-gray-900
                                  "
                                >
                                  Notes
                                </label>
                                <div class="mt-1">
                                  <textarea
                                    v-model="notes"
                                    id="notes"
                                    placeholder="Notes"
                                    rows="3"
                                    class="
                                      shadow-sm
                                      block
                                      w-full
                                      focus:ring-indigo-500
                                      focus:border-indigo-500
                                      sm:text-sm
                                      border border-gray-300
                                      rounded-md
                                    "
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-if="errors.length !== 0" class="bg-red-50 p-4">
                        <div class="flex">
                          <div class="flex-shrink-0">
                            <XCircleIcon
                              class="h-5 w-5 text-red-400"
                              aria-hidden="true"
                            />
                          </div>
                          <div class="ml-3">
                            <h3 class="text-sm font-medium text-red-800">
                              There were errors with your submission
                            </h3>
                            <div class="mt-2 text-sm text-red-700">
                              <ul class="list-disc pl-5 space-y-1">
                                <li v-for="error in errors" :key="error">
                                  {{ error }}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-if="success" class="rounded-md bg-green-50 p-4">
                        <div class="flex">
                          <div class="flex-shrink-0">
                            <CheckCircleIcon
                              class="h-5 w-5 text-green-400"
                              aria-hidden="true"
                            />
                          </div>
                          <div class="ml-3">
                            <h3 class="text-sm font-medium text-green-800">
                              {{ success }}
                            </h3>
                          </div>
                        </div>
                      </div>

                      <div class="flex-shrink-0 px-4 py-4 flex justify-end">
                        <button
                          :disabled="isGenerating"
                          @click="startGenerating"
                          class="
                            ml-3
                            inline-flex
                            justify-center
                            py-2
                            px-4
                            border border-transparent
                            shadow-sm
                            text-sm
                            font-medium
                            rounded-md
                            text-white
                            bg-indigo-600
                            hover:bg-indigo-700
                            focus:outline-none
                            focus:ring-2
                            focus:ring-offset-2
                            focus:ring-indigo-500
                          "
                        >
                          {{ isGenerating ? "Wait.." : "Generate" }}
                        </button>
                        <button
                          @click="deleteOpen = true"
                          v-if="isEdit"
                          type="button"
                          class="
                            ml-4
                            inline-flex
                            items-center
                            justify-center
                            px-4
                            py-2
                            border border-transparent
                            font-medium
                            rounded-md
                            text-red-700
                            bg-red-100
                            hover:bg-red-200
                            focus:outline-none
                          "
                        >
                          Delete
                        </button>
                        <button
                          :disabled="loading"
                          type="submit"
                          class="
                            ml-4
                            inline-flex
                            justify-center
                            py-2
                            px-4
                            border border-transparent
                            shadow-sm
                            text-sm
                            font-medium
                            rounded-md
                            text-white
                            bg-indigo-600
                            hover:bg-indigo-700
                            focus:outline-none
                          "
                        >
                          {{ loading ? "Wait.." : "Save" }}
                        </button>
                      </div>
                    </form>
                  </div>

                  <!-- /End replace -->
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

  <QueueCallDelete :id="id ?? 0" :open="deleteOpen" @close="addClosed" />
</template>

<script>
import QueueCallDelete from "@/modules/queues/views/delete_call.vue";
import StorageUtil from "@/utils/LocalStorageUtil.js";

import {
  Menu,
  MenuItem,
  MenuItems,
  MenuButton,
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import {
  XCircleIcon,
  CheckCircleIcon,
  ChevronDownIcon,
} from "@heroicons/vue/solid";
import { XIcon } from "@heroicons/vue/outline";
import queuesApi from "@/network/refactored/queues.js";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    Menu,
    MenuItem,
    MenuItems,
    MenuButton,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
    XCircleIcon,
    CheckCircleIcon,
    ChevronDownIcon,
    QueueCallDelete,
  },

  emits: ["close"],

  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      id: 0,
      isEdit: false,
      isGenerating: false,
      loading: false,
      errors: [],
      success: null,

      number: "",
      caller_id_num: "",
      caller_id_name: "",
      destination: "",
      notes: "",

      deleteOpen: false,
      selectedEndpoint: 0,
    };
  },

  methods: {
    addClosed(removed) {
      this.deleteOpen = false;
      if (removed) {
        this.$emit("close");
      }
    },

    async startGenerating() {
      this.$Progress.start();
      this.isGenerating = true;
      this.errors = [];
      try {
        let result = await this.generate({ number: this.number });
        this.caller_id_num = result?.callerid;
        this.destination = result?.destination;
      } catch (_) {
        this.errors.push("Unable to generate");
      }
      this.isGenerating = false;
      this.$Progress.finish();
    },

    reset(data) {
      this.id = data?.id ?? 0;
      this.loading = false;
      this.errors = [];
      this.success = null;

      this.isEdit = !!data;

      this.number = data?.number ?? "";
      this.caller_id_num = data?.caller_id_num ?? "";
      this.caller_id_name = data?.caller_id_name ?? "";
      this.notes = data?.notes ?? "";
    },

    async save(e) {
      e.preventDefault();
      if (!this.validate()) return;

      this.loading = true;
      try {
        if (this.isEdit) {
          const { data } = await queuesApi.updateQueueCall(
            this.id,
            this.number,
            this.caller_id_num,
            this.caller_id_name,
            this.notes
          );
          this.success = "Queue Call updated successfully";
          this.editQueueCall({ queueCall: data });
        } else {
          const { data } = await queuesApi.createQueueCall(
            this.$route.params.id,
            this.number,
            this.caller_id_num,
            this.caller_id_name,
            this.notes
          );
          this.addQueueCall({ queueCall: data });
          this.reset();
          this.success = "Queue Call added successfully";
        }
      } catch (error) {
        if (error.response) {
          this.errors.push(error.response.data["message"]);
        } else {
          this.errors.push("Error while trying to submit data");
        }
      } finally {
        this.loading = false;
      }
    },

    validate() {
      this.errors = [];
      this.success = null;

      let isValid = true;
      if (this.number === "") {
        isValid = false;
        this.errors.push("Enter a valid Phone number");
      }
      if (this.caller_id_num === "") {
        isValid = false;
        this.errors.push("Enter a valid Caller ID number");
      }

      return isValid;
    },

    ...mapActions("queues", ["addQueueCall", "editQueueCall"]),
    ...mapActions("place", ["generate"]),
  },

  computed: {
    userType() {
      return StorageUtil.getUserType();
    },
    ...mapGetters("calls", ["DIDs"]),
  },
};
</script>
