<template>
  <div class="flex flex-col mx-4">
    <div class="overflow-x-auto">
      <h1 class="inline-flex align-middle">{{ queue?.name }}</h1>
      <button
        @click="openAddQueueCall(null)"
        type="button"
        class="
          mb-1
          float-right
          relative
          inline-flex
          items-center
          px-3
          py-2
          border border-transparent
          shadow-sm
          text-sm
          rounded-md
          text-white
          bg-gray-700
          hover:bg-gray-800
          focus:outline-none
        "
      >
        Add Queue Call
      </button>

      <div class="py-2 align-middle inline-block min-w-full">
        <div
          class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
        >
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="
                    px-6
                    py-3
                    text-left text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  User
                </th>
                <th
                  scope="col"
                  class="
                    px-6
                    py-3
                    text-left text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  Number
                </th>
                <th
                  scope="col"
                  class="
                    px-6
                    py-3
                    text-left text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  CID
                </th>
                <th
                  scope="col"
                  class="
                    px-6
                    py-3
                    text-left text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  CID Name
                </th>
                <th
                  scope="col"
                  class="
                    px-6
                    py-3
                    text-left text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  Notes
                </th>
                <th
                  scope="col"
                  class="
                    px-6
                    py-3
                    text-left text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  Status
                </th>
                <th scope="col" class="relative px-6 py-3">
                  <ClockIcon
                    v-if="loading"
                    class="animate-spin h-4. w-5 text-gray-700"
                    aria-hidden="true"
                  />
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <QueueCallItem
                @edit="openAddQueueCall"
                v-for="call in queue?.calls"
                :key="call.id"
                :call="call"
              />
            </tbody>
          </table>

          <div v-if="queue?.calls.length === 0" class="mx-3 mb-2">
            <EmptyTable label="You do not have any calls in this queue." />
          </div>
        </div>
      </div>
    </div>
  </div>

  <AddQueueCall
    ref="addQueueCall"
    :open="addQueueCallOpened"
    @close="addQueueCallOpened = false"
  />
</template>

<script>
import { ClockIcon } from "@heroicons/vue/solid";
import QueueCallItem from "@/modules/queues/views/queue_call_item.vue";
import AddQueueCall from "@/modules/queues/views/add_queue_call.vue";
import EmptyTable from "@/widgets/common/EmptyTable.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    ClockIcon,
    QueueCallItem,
    EmptyTable,
    AddQueueCall,
  },
  data() {
    return {
      addQueueCallOpened: false,
    };
  },
  created() {
    this.getQueueDetails(this.$route.params.id);
  },
  methods: {
    openAddQueueCall(data) {
      this.$refs.addQueueCall.reset(data);
      this.addQueueCallOpened = true;
    },
    ...mapActions("queues", ["getQueueDetails"]),
  },
  computed: {
    ...mapGetters("queues", ["queue", "loading"]),
  },
};
</script>
