<template>
  <tr>
    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
      {{ call.user }}
    </td>
    <td class="px-6 py-4 whitespace-nowrap">
      <div class="flex items-center">
        <div>
          <div class="text-sm font-medium text-gray-900">
            {{ call.number }}
          </div>
        </div>
      </div>
    </td>
    <td class="px-6 py-4 whitespace-nowrap">
      <div class="flex items-center">
        <div>
          <div class="text-sm font-medium text-gray-900">
            {{ call.caller_id_num }}
          </div>
        </div>
      </div>
    </td>
    <td class="px-6 py-4 whitespace-nowrap">
      <div class="flex items-center">
        <div>
          <div class="text-sm font-medium text-gray-900">
            {{ call.caller_id_name }}
          </div>
        </div>
      </div>
    </td>
    <td class="px-6 py-4 whitespace-nowrap">
      <div class="flex items-center">
        <div>
          <div class="text-sm font-medium text-gray-900">
            {{ call.notes }}
          </div>
        </div>
      </div>
    </td>
    <td class="px-6 py-4 whitespace-nowrap">
      <div class="flex items-center">
        <div>
          <div class="text-sm font-medium text-gray-900">
            {{ call.status }}
          </div>
        </div>
      </div>
    </td>
    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium">
      <a @click="edit" href="#" class="text-indigo-600 hover:text-indigo-900"
        >Edit</a
      >
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    call: {
      type: Object,
      default: null,
    },
  },
  emits: ["edit"],
  methods: {
    edit() {
      this.$emit("edit", this.call);
    },
  },
};
</script>
